module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sawdust Art Festival","short_name":"Sawdust","start_url":"/mixed/","background_color":"#FAEACD","theme_color":"#FAEACD","display":"minimal-ui","icon":"src/images/sawdust-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
